import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import humanize from "humanize-duration";
export default ({ data }) => (
  <Layout>
    <section className="section">
      <div className="container">
        <h1 className="title">{data.wakatime.id}</h1>
        <div>
          {humanize(data.wakatime.data.grand_total.total_seconds * 1000, {
            language: "ja"
          })}
        </div>
      </div>
    </section>
  </Layout>
);

export const query = graphql`
  query($id: String!) {
    wakatime(id: { eq: $id }) {
      id
      data {
        grand_total {
          hours
          digital
          text
          total_seconds
          minutes
        }
        editors {
          name
          digital
          text
          seconds
          total_seconds
          percent
          minutes
          hours
        }
        languages {
          name
          digital
          text
          seconds
          total_seconds
          percent
          minutes
          hours
        }
        range {
          text
          start
          date
          end
          timezone
        }
        operating_systems {
          hours
          name
          digital
          text
          seconds
          total_seconds
          percent
          minutes
        }
        entities {
          name
          digital
          text
          seconds
          type
          total_seconds
          percent
          minutes
          hours
        }
        projects {
          text
          percent
          total_seconds
          minutes
          hours
          name
          digital
          seconds
        }
        dependencies {
          text
          seconds
          total_seconds
          percent
          minutes
          hours
          name
          digital
        }
        categories {
          name
          digital
          text
          seconds
          total_seconds
          percent
          minutes
          hours
        }
      }
    }
  }
`;
